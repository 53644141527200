import { defineMessages } from 'react-intl';
import type { LocalePerProduct } from 'contracts';

const seo: LocalePerProduct = {
  livejasmin: defineMessages({
    title: {
      id: 'pages.musicLibrary.seo.livejasmin.title',
      defaultMessage: 'Music Library',
    },
    description: {
      id: 'pages.musicLibrary.seo.livejasmin.description',
      defaultMessage: 'Music Library',
    },
  }),
  oranum: defineMessages({
    title: {
      id: 'pages.musicLibrary.seo.oranum.title',
      defaultMessage: 'Music Library',
    },
    description: {
      id: 'pages.musicLibrary.seo.oranum.description',
      defaultMessage: 'Music Library',
    },
  }),
};

export default {
  pages: {
    musicLibrary: {
      seo,
      navigationMenu: {
        ...defineMessages({
          label: {
            id: 'pages.musicLibrary.navigationMenu.label',
            defaultMessage: 'Music Library',
          },
        }),
      },
      favorites: defineMessages({
        addSuccess: {
          id: 'pages.musicLibrary.favorites.addSuccess',
          defaultMessage: '<b>{songTitle}</b> added to Favorites',
        },
        addLimitReached: {
          id: 'pages.musicLibrary.favorites.addLimitReached',
          defaultMessage: 'Favorites limit reached! You can have a max. of {limit} songs.',
        },
        removeSuccess: {
          id: 'pages.musicLibrary.favorites.removeSuccess',
          defaultMessage: '<b>{songTitle}</b> removed from Favorites',
        },
      }),
    },
  },
};
