import React from 'react';
import type { Route } from 'contracts';

import parentId from '../../@@global/id';

import id from './id';

const AsyncPage = React.lazy(() => import('../playlist'));

const routes: Route = {
  parent: parentId,
  name: id.musicLibraryPlaylist,
  path: '/playlist/{categoryName}',
  searchParams: ['order:list(asc|desc|genre|duration)', 'search', 'page', 'duration', 'subcategories'],
  element: <AsyncPage />,
};

export default routes;
