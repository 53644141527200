import React from 'react';
import type { Route } from 'contracts';

import parentId from '../../@@global/id';

import id from './id';

const AsyncPage = React.lazy(() => import('../categories'));
const AsyncSkeletonPage = React.lazy(() => import('../categories.skeleton'));

const routes: Route = {
  parent: parentId,
  name: id,
  path: '/',
  element: <AsyncPage />,
  skeleton: <AsyncSkeletonPage />,
};

export default routes;
