import React from 'react';
import type { Route } from 'contracts';

import parentId from '../../@@global/id';

import id from './id';

const AsyncPage = React.lazy(() => import('../../playlist/playlist'));

const routes: Route = {
  parent: parentId,
  name: id.musicLibraryPlaylistSearch,
  path: '/search',
  searchParams: ['order:list(asc|desc|genre|duration)', 'search', 'page', 'categories', 'duration'],
  element: <AsyncPage />,
};

export default routes;
